const StatusBox = ({ status }) => {
  if (status === 200) {
    return <div className="status-box success">200 | Success</div>;
  } else if (status === 500) {
    return <div className="status-box error">500 | Error</div>;
  }
  return <div className="status-box">Unknown Status</div>; // Optional: Handle other status codes or unknown cases
};

export default StatusBox;
