import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import hamburgerMenu from "./assets/hamburgerMenu.svg";
import sinarmasLogo from "./assets/sinarmasLogo.svg";
import signoutIcon from "./assets/signoutIcon.svg";
import userIcon from "./assets/userIcon.jpg";
import "./App.css";

import { ReactComponent as DashboardIcon } from "./assets/dashboardIcon.svg";
import { ReactComponent as FunctionIcon } from "./assets/functionMgmtIcon.svg";
import { ReactComponent as FallbackIcon } from "./assets/fallbackMgmtIcon.svg";
import { ReactComponent as UserRoleIcon } from "./assets/userroleIcon.svg";
import { ReactComponent as FallbackReportIcon } from "./assets/fallbackReportIcon.svg";

import FallbackManagement from "./FallbackMgmt/FallbackManagement";
import FallbackDetails from "./FallbackMgmt/FallbackDetails";
import InDevelopment from "./InDevelopment/InDevelopment";

const WithRouter = ({ children }) => {
  return <Router>{children}</Router>;
};

const App = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("Dashboard");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathToTitle = {
      "/fallback-management": "Fallback Management",
      "/prompt-management": "Prompt Management",
      "/analytics-dashboard": "Analytics Dashboard",
      "/user-role-management": "User & Role Management",
      "/fallback-report": "Fallback Report",
    };

    setActiveTab(pathToTitle[location.pathname] || "Fallback Management");

    if (location.pathname === "/") {
      navigate("/fallback-management", { replace: true });
    }
  }, [location.pathname, navigate]);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleTabClick = (tabTitle, path) => {
    setActiveTab(tabTitle);
    navigate(path); 
  };

  const renderTabs = () => {
    const tabs = [
      { title: "Fallback Management", icon: <FallbackIcon className="tab-icon"/>, path: "/fallback-management" },
      { title: "Prompt Management", icon: <FunctionIcon className="tab-icon"/>, path: "/prompt-management" },
      { title: "Analytics Dashboard", icon: <DashboardIcon className="tab-icon fill"/>, path: "/analytics-dashboard" },
      { title: "User & Role Management", icon: <UserRoleIcon className="tab-icon"/>, path: "/user-role-management" },
      { title: "Fallback Report", icon: <FallbackReportIcon className="tab-icon"/>, path: "/fallback-report" }
    ];
  
    return tabs.map((tab) => (
      <div
        key={tab.title}
        className={`tab ${activeTab === tab.title ? "active" : ""}`}
        onClick={() => handleTabClick(tab.title, tab.path)}
      >
        {tab.icon}
        {isSidePanelOpen && <p className="tab-name">{tab.title}</p>}
      </div>
    ));
  };

  const renderBottomTabs = () => {
    return (
      <div className={`bottom-tabs ${isSidePanelOpen ? "" : "side-panel-closed"}`}>
        <div className="tab" onClick={() => alert("User Profile")}>
          <img src={userIcon} className="user-icon" alt="User Icon" />
          {isSidePanelOpen && <p className="tab-name">User</p>}
        </div>
        <div className="tab" onClick={() => alert("Sign Out")}>
          <img src={signoutIcon} className="tab-icon" alt="Signout Icon" />
          {isSidePanelOpen && <p className="sign-out">Sign Out</p>}
        </div>
      </div>
    );
  };

  return (
    <div className="main-container">
      <div className={`side-panel ${isSidePanelOpen ? "open" : "closed"}`}>
        <img
          src={isSidePanelOpen ? sinarmasLogo : hamburgerMenu}
          className="logo"
          onClick={toggleSidePanel}
          alt="sinarmas"
        />
        {!isSidePanelOpen && <div className="separator"></div>}
        <div className={`tabs ${isSidePanelOpen ? "" : "side-panel-closed"}`}>
          {renderTabs()}
        </div>
        {renderBottomTabs()}
      </div>
      <div className={`content-area ${isSidePanelOpen ? "shifted" : ""}`}>
        <Routes>
          <Route path="/fallback-management" element={<FallbackManagement />} />
          <Route path="/fallback-management/details" element={<FallbackDetails />} />
          <Route path="/prompt-management" element={<InDevelopment />} />
          <Route path="/analytics-dashboard" element={<InDevelopment />} />
          <Route path="/user-role-management" element={<InDevelopment />} />
          <Route path="/fallback-report" element={<InDevelopment />} />
        </Routes>
      </div>
    </div>
  );
};

export default () => (
  <WithRouter>
    <App />
  </WithRouter>
);