import React, { useState, useEffect } from "react";
import success from "../assets/success.svg";
import cancelIcon from "../assets/cancelIcon.svg";

const SampleQuestionModal = ({ item, isOpen, onClose }) => {
  const [question, setQuestion] = useState(item.question || "");
  const [capability, setCapability] = useState("Unknown Capability");
  const [capabilities, setCapabilities] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const fetchCapabilities = async () => {
      try {
        const response = await fetch('https://mvd-cms-api.nawatech.co/api/sma/fallback/get-master-capability');
        const data = await response.json();
        setCapabilities(data.data);
      } catch (error) {
        console.error('Error fetching capabilities:', error);
      }
    };

    if (isOpen && item) {  
      fetchCapabilities();
      setQuestion(item.question || "");
      setIsSubmitted(false);
    }
  }, [isOpen, item]);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      question: question,
      detected_capability: item.detected_capability,
      capability_function: capability,
      doc_id: item.id, 
    };

    try {
      const response = await fetch('https://mvd-cms-api.nawatech.co/api/sma/fallback/create-sample-question', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        console.log('Sample question created successfully.');
        setIsSubmitted(true);
      } else {
        console.error('Failed to create sample question.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {isSubmitted ? (
          <div className="success-message">
            <img src={cancelIcon} className="close-button" onClick={onClose} alt="cancel"/>
            <img src={success} alt="Success" />
            <h2>Data has been successfully added!</h2>
            <p>Success! The data has been added as a sample question.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="modal-form">
            <h2 className="modal-title">Add Sample Question</h2>
            <hr className="modal-separator" />
            <div className="modal-form-group">
              <label htmlFor="question">Question</label>
              <input
                id="question"
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
              />
            </div>
            <div className="modal-form-group">
              <label htmlFor="detected_capability">Detected Capability</label>
              <input
                id="detected_capability"
                type="text"
                value={item.detected_capability}
                className="suggested-cap-input"
                readOnly
              />
            </div>
            <div className="modal-form-group">
              <label htmlFor="capability">Capability/Function</label>
              <select
                id="capability"
                value={capability}
                onChange={(e) => setCapability(e.target.value)}
                required
              >
                {capabilities.map((capability, index) => (
                  <option key={index} value={capability}>
                    {capability}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal-buttons">
              <button type="button" onClick={onClose}>
                Cancel
              </button>
              <button type="submit">Save</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SampleQuestionModal;